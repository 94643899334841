import React from 'react';
import styled from '@emotion/styled';

const Content = styled.div`
    width: 100%;
    max-width: 600px;
    padding: 40px 0;
    position: relative;
    margin: auto;
`;

const LinkArea = styled.div`
    color: #00838f;
    display: block;
    padding: 5px;
    text-align: center;
`;

const LinkCustom = styled.a`
    color: #00838f;
    text-decoration: none;
`;

const TitleArea = styled.div`
    color: #00838f;
    text-align: center;
    padding: 10px;
`;

const Title = styled.span`
    font-weight: bold;
    font-size: 1.5em;
`;

interface ImageUrl {
    text: string;
    url: string;
}

interface ImagesProps {
    links: ImageUrl[];
}

const Images: any = (props: ImagesProps) => {
    const {
        links
    } = props;

    return (
        <Content>
            <TitleArea>
                <Title>Images vectorisés</Title>
            </TitleArea>
            {links.map((link) => {
                return (
                    <LinkArea>
                        <LinkCustom href={link.url} target="_blank">{link.text}</LinkCustom>
                    </LinkArea>
                );
            })}
        </Content>
    );
}

export default Images;